import  { useCallback, useRef } from "react";
import PropTypes from "prop-types";

/**
 * A custom React hook that returns a stable callback reference which
 * always points to the latest function passed in. The returned function
 * will have the same type signature as the provided `callback`.
 *
 * @template TArgs
 * @template TReturn
 *
 * @param {(...args: TArgs) => TReturn} callback - The function to be memoized and called with the latest version.
 * @returns {(...args: TArgs) => TReturn} - A memoized callback function that invokes the latest version of the passed-in callback.
 */
const useEvent = (callback) => {
	const handlerRef = useRef(callback);
	handlerRef.current = callback;
	return useCallback((...args) => handlerRef.current?.(...args), []);
};

export default useEvent;
