export const ADMIN_API_LISTENER_EVENTS = {
	BALANCE: "Balance",
	LOGOUT: "Logout",
	REPRINT_REQUEST: "ReprintRequest",
	BET_SLIP: "BetSlip",
	BET_SLIP_BET: "BetSlipBet",
	BONUS: "Bonus"
};

export const CASHIER_API_LISTENER_EVENTS = {
	BALANCE: "Balance",
	BONUS: "Bonus",
	BET_SLIP: "BetSlip",
	BET_SLIP_BET: "BetSlipBet",
	JACKPOTS: "Jackpots",
	WON_JACKPOTS: "WonJackpots"
};

export const JOB_API_LISTENER_EVENTS = {
	BALANCE: "Balance",
	EVENTS: "Events",
	EVENT_STATE: "EventState",
	BET_SLIP: "BetSlip",
	BET_SLIP_BET: "BetSlipBet"
};

export const ADMIN_API_INVOKE_EVENTS = {
	SUBSCRIBE: "Subscribe",
	UNSUBSCRIBE: "Unsubscribe"
};

export const JOB_API_INVOKE_EVENTS = {
	SUBSCRIBE: "Subscribe",
	UNSUBSCRIBE: "Unsubscribe"
};

export const CASHIER_API_INVOKE_EVENTS = {
	UPDATE_SESSION: "UpdateSession",
	SUBSCRIBE: "Subscribe",
	UNSUBSCRIBE: "Unsubscribe"
};

export const UPDATE_SESSION_INTERVAL_MILLISECONDS = 60000;