import i18n from "translations/config";
import { PROJECT_TYPE } from "constants/common.constants";
import { GAME_TYPE, GAME_EVENT_TYPE, CUP_GAMES_FINAL_ROUND_NUMBER, PENALTY_SCENE_INFO_NAMES } from "constants/game.constants";
import { RESULTS_PRINTING_OPTIONS_VALUES } from 'constants/ticket.constants';
import fullScreenAPI from './fullscreen';
import { VALIDATION_TYPES } from 'constants/ui.constants';

/**
	* Function to create full path for loading it from CDN
	* @param {string} path file location
	* @returns {string} full path of file from CDN
*/
export const buildPathToStaticFolderOfCDN = (path) => {
	return`${import.meta.env.SYSTEM_CDN_URL}/static/${path?.toLowerCase()}`;
}

/** Get query params
 * @function
 * @param {string} path - location path
 * @returns {object} - query params
 */
export const getUrlVars = (path) => {
	const href = path || window.location.href;
	const vars = {};
	const parts = href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
		vars[key] = value;
	});
	return vars;
};

/** Check if device is mobile
 * @function
 * @returns {boolean}
 */
export const isMobile = () => {
	const isMobileBrowser = {
		Android: () => navigator.userAgent.match(/Android/i) !== null,
		BlackBerry: () => navigator.userAgent.match(/BlackBerry/i) !== null,
		iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null,
		Opera: () => navigator.userAgent.match(/Opera Mini/i) !== null,
		Windows: () => navigator.userAgent.match(/IEMobile/i) !== null || navigator.userAgent.match(/WPDesktop/i) !== null,
		any: () => isMobileBrowser.Android() || isMobileBrowser.BlackBerry() || isMobileBrowser.iOS() || isMobileBrowser.Opera() || isMobileBrowser.Windows()
	};

	return isMobileBrowser.any();
};

/** Check if device is ios
 * @function
 * @returns {boolean}
 */
export const isIOS = () => navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;

/** Check if device is ipad
 * @function
 * @returns {boolean}
 */
export const isTablet = () => /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent.toLowerCase());

/** View page in fullscreen
 * @function
 */
export const openFullscreen = () => {
	const elem = document.documentElement;
	elem.classList.add("fullscreen");
	if (elem.requestFullscreen) {
		elem.requestFullscreen();
	} else if (elem.mozRequestFullScreen) {
		/* Firefox */
		elem.mozRequestFullScreen();
	} else if (elem.webkitRequestFullscreen) {
		/* Chrome, Safari and Opera */
		elem.webkitRequestFullscreen();
	} else if (elem.msRequestFullscreen) {
		/* IE/Edge */
		elem.msRequestFullscreen();
	}
};

/** Exit from fullscreen
 * @function
 */
export const closeFullscreen = () => {
	document.documentElement.classList.remove("fullscreen");

	if (document.exitFullscreen) {
		document.exitFullscreen();
	} else if (document.mozCancelFullScreen) {
		/* Firefox */
		document.mozCancelFullScreen();
	} else if (document.webkitExitFullscreen) {
		/* Chrome, Safari and Opera */
		document.webkitExitFullscreen();
	} else if (document.msExitFullscreen) {
		/* IE/Edge */
		document.msExitFullscreen();
	}
};

/** Is window is in fullscreen mode
 * @function
 * @returns {boolean}
 */
export const isFullscreen = () => {
	return fullScreenAPI.isFullscreen;
};

/** Make string to lowercase
 * @function
 * @param {string} str
 * @returns {string}
 */
export const toLowerCase = (str) => {
	return str && str.toLowerCase();
};

/** function which filter enum values, to values which are true with given binary number
 * @function
 * @param {array} flags - array of all enum values(1,2,4,8,16,...)
 * @param {binaryNum} number - binary integer, which represents the array of selected flags
 * @returns {array}
 */
export const binaryToFlags = (flags, binaryNum) => flags.filter((f) => f & binaryNum);

/** function which make a binary integer, from array of enum values, so server can detect selected enum values
 * @function
 * @param {array} arr - array of enum values(1,2,4,8,16,...)
 * @returns {number}
 */
export const flagsToBinary = (arr) => arr.reduce((a, b) => a ^ b, 0);

/** function which prints DOM elemnt content
 * @function
 * @param {string} id - The DOM elemnt id to print
 */
export const printElement = (id, onSuccess, printTwice) => {
	const element = document.getElementById(id);
	const styleTags = Array.prototype.slice.call(document.getElementsByTagName("style"));
	const linkTags = Array.prototype.slice.call(document.getElementsByTagName("link")).filter((l) => l.rel === "stylesheet");
	const elementsToCopy = [...styleTags, ...linkTags];

	const runPrint = (isLast) => {
		let pri;
		const iframe = document.getElementById(id + "-iframe-" + (isLast ? "2" : "1")) ? document.getElementById(id + "-iframe-" + (isLast ? "2" : "1")) : document.createElement("iframe");
		iframe.setAttribute("id", id + "-iframe-" + (isLast ? "2" : "1"));
		iframe.setAttribute("style", "height: 0px; width: 0px; position: absolute;");
		iframe.setAttribute("height", "560px");
		iframe.setAttribute("width", "420px");
		document.body.appendChild(iframe);
		pri = iframe.contentWindow;
		pri.document.open();
		pri.document.write("<div class='vs--print-view'>" + element.innerHTML.replace("%%%TICKET_NUMBER%%%", isLast ? "2" : "1") + "</div>");
		elementsToCopy.forEach((el) => {
			const node = el.cloneNode(true);
			node.setAttribute("media", "print");
			pri.document.body.appendChild(el.cloneNode(true));
		});

		const styleTag = document.createElement("style");
		styleTag.innerHTML = "html, body{min-width: 0!important; width: 100%!important; background-color: #fff!important; will-change: auto!important;transform: none!important; margin: auto}";
		pri.document.body.appendChild(styleTag);

		pri.document.close();
		pri.focus();

		pri.addEventListener(
			"load",
			() => {
				pri.print();
				isLast && typeof onSuccess === "function" && onSuccess();
			},
			false
		);
	};
	runPrint(!printTwice);
	if (printTwice) {
		setTimeout(() => {
			runPrint(true);
		}, 500);
	}
};

/** function which prints DOM elemnts content
 * @function
 * @param {string} ids - The array of DOM elemnts id for print
 */

export const multiPrintElement = (ids, callback) => {
	if (!Array.isArray(ids) || !ids.length) {
		return;
	}
	const styleTags = Array.prototype.slice.call(document.getElementsByTagName("style"));
	const linkTags = Array.prototype.slice.call(document.getElementsByTagName("link")).filter((l) => l.rel === "stylesheet");
	const elementsToCopy = [...styleTags, ...linkTags];
	for (let i = 0; i < ids.length; i++) {
		runPrint(ids[i], i);
	}
	function runPrint(id, num) {
		const element = document.getElementById(id);
		if (!element) {
			return;
		}
		let pri;
		const iframe = document.getElementById(id + "-iframe") || document.getElementById(id + "-iframe-" + num) || document.createElement("iframe");
		iframe.setAttribute("id", id + "-iframe-" + num);
		iframe.setAttribute("style", "height: 0px; width: 0px; position: absolute;");
		iframe.setAttribute("height", "560px");
		iframe.setAttribute("width", "420px");
		document.body.appendChild(iframe);
		pri = iframe.contentWindow;
		pri.document.open();
		pri.document.write("<div class='vs--print-view'>" + element.innerHTML + "</div>");
		elementsToCopy.forEach((el) => {
			const node = el.cloneNode(true);
			node.setAttribute("media", "print");
			pri.document.body.appendChild(el.cloneNode(true));
		});
		const styleTag = document.createElement("style");
		styleTag.innerHTML = "html, body{min-width: 0!important; width: 100%!important; background-color: #fff!important; will-change: auto!important;transform: none!important; margin: auto}";
		pri.document.body.appendChild(styleTag);
		pri.document.close();
		pri.focus();
		pri.addEventListener(
			"load",
			function () {
				pri.print();
				typeof callback === "function" && callback(id, num);
			},
			false
		);
	}
};

/** Make number to float(string)
 * @function
 * @param {number} num
 * @param {number} decimal - number counts after floating number
 * @returns {string}
 */
export const toFixed = (num, decimal) => Number(num).toFixed(decimal);

/** Beutify number by adding spaces
 * @function
 * @param {number} value
 * @param {object} currency
 * @returns {string}
 */
export const makeCurrencyText = (value, currency) => {
	const currencyCode = currency?.code ?? "";
	const decimalCount = currency?.decimalCount ?? 2;
	const num = Number(value);
	return numberWithSpaces(toFixed(num, decimalCount)) + " " + currencyCode;
};

/** Validate if the value is correct for stake input field
 * @function
 * @param {string} amount
 * @param {number} decimal - number counts after floating number
 * @returns {boolean}
 */
export const isValidAmount = (amount, decimal) => {
	const rgx = /^[0-9]*\.?[0-9]*$/;
	return amount.match(rgx) && !amount.toString().startsWith("0") && !amount.toString().startsWith(".") && (!amount.toString().split(".")[1] || amount.toString().split(".")[1].length <= decimal);
};

/** Get the project type
 * @function
 * @returns {number} - 1|2 (IFRAME, STANDALONE)
 */
export const getProjectType = () => {
	let type = PROJECT_TYPE.STANDALONE;

	if (getUrlVars()["sessionId"] || getUrlVars()["type"] === "iframe") {
		type = PROJECT_TYPE.IFRAME;
	}

	return type;
};

/** Copy text to clipboard
 * @function
 * @param {string} text - text to copy
 * @returns {string}
 */

export const copyToClipboard = (text) => {
	const input = document.createElement("input");
	input.setAttribute("value", text);
	document.body.appendChild(input);
	input.select();
	const result = document.execCommand("copy");
	document.body.removeChild(input);
	return result;
};

/** Insert text at caret position on the input
 * @function
 * @param {HTMLFormElement} input - the input element
 * @param {string} value - the value
 */
export const insertAtCursor = (input, value) => {
	let sel;
	//IE support
	if (document.selection) {
		input.focus();
		sel = document.selection.createRange();
		sel.text = value;
	} else if (input.selectionStart || input.selectionStart == "0" || window.navigator.userAgent.indexOf("Edge") > -1) {
		let startPos = input.selectionStart;
		let endPos = input.selectionEnd;
		input.value = input.value.substring(0, startPos) + value + input.value.substring(endPos, input.value.length);
		let pos = startPos + value.length;
		input.focus();
		input.setSelectionRange(pos, pos);
	} else {
		input.value += value;
	}
};

/** Remove char at caret position on the input
 * @function
 * @param {HTMLFormElement} input - the input element
 */
export const removeAtCursor = (input) => {
	let sel;
	//IE support
	if (document.selection) {
		input.focus();
		sel = document.selection.createRange();
		sel.text = "";
	} else if (input.selectionStart || input.selectionStart == "0" || window.navigator.userAgent.indexOf("Edge") > -1) {
		let startPos = input.selectionStart;
		let endPos = input.selectionEnd;
		input.value = input.value.substr(0, startPos - 1) + input.value.substring(endPos, input.value.length);
		input.focus();
		input.setSelectionRange(startPos - 1, startPos - 1);
	} else {
		input.value = input.value.substring(0, input.value.length - 1);
	}
};

/** Beutify number by adding spaces
 * @function
 * @param {number} num
 * @returns {string}
 */
export const numberWithSpaces = (num) => {
	if (num === null || num === undefined) return "";
	const parts = num.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	return parts.join(".");
};

/** Returns all posible combinations from array items
 * @function
 * @param {string} str - all characters string
 * @returns {array}
 */
export const getAllCombinations = (str, curstr = "") => {
	if (curstr.length == str.length) return [curstr];
	var ret = [];
	for (var i = 0; i < str.length; i++) {
		if (!curstr.includes(str[i])) ret.push.apply(ret, getAllCombinations(str, curstr + str[i]));
	}
	return ret;
};

/** Lock screen orientation for mobile
 * @function
 * @param {string} mode - Orientation mode
 */
export const lockOrientation = (mode) =>
	(screen.orientation && screen.orientation.lock && screen.orientation.lock(mode).then(Function.prototype, (err) => console.log(err))) || (screen.mozLockOrientation && screen.mozLockOrientation(mode)) || (screen.msLockOrientation && screen.msLockOrientation(mode));

/** UnLock screen orientation for mobile
 * @function
 */
export const unLockOrientation = () => (screen.orientation && screen.orientation.unlock && screen.orientation.unlock()) || (screen.mozUnlockOrientation && screen.mozUnlockOrientation()) || (screen.msUnockOrientation && screen.msUnockOrientation());

/** Function to group data by qty
 * @function
 */
export const groupByQty = (arr, qty) => {
	if (!Array.isArray(arr) || typeof qty !== "number") {
		return [];
	}
	return arr.reduce((acc, elem) => {
		if (!Array.isArray(acc[acc.length - 1]) || acc[acc.length - 1].length === qty) {
			acc.push([]);
		}
		acc[acc.length - 1].push(elem);
		return acc;
	}, []);
};

/** Function to get elements from matrix by line
 * @function
 */
export const getLineFromMatrix = (matrix, index, isRow = true) => {
	if (isRow) {
		return matrix[index];
	}
	return matrix.map((arr) => arr[index]);
};

/** Function to get unic random numbers by specific range and count
 * @function
 * @param {number} count - Random numbers count
 * @param {number} range - Max range of random numbers
 * @param {number} movePoint - Number for moveing random value
 * @param {function} raundingFunction - Function for raunding random number
 * @returns {array}
 */
export const getUniqueRandomNumbersByRangeAndCount = (count = 0, range = 1, movePoint = 0, raundingFunction = Math.floor) => {
	const container = new Set();
	while (container.size < count) {
		container.add(raundingFunction(Math.random() * range + movePoint));
	}
	return [...container];
};

/** Checks is the game is racing
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */

export const isRacingGame = (type) => [GAME_TYPE.HORSE_RACING, GAME_TYPE.GREYHOUNDS_RACE, GAME_TYPE.HORSE_STEEPLECHASING].includes(type);

/** Checks is the game is league
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */

export const isLeagueGame = (type) => type === GAME_TYPE.ENGLISH_LEAGUE;

/** Checks is the game is simple cup
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isSimpleCupGame = (type) => [GAME_TYPE.AFRICAN_CUP, GAME_TYPE.WORLD_CUP, GAME_TYPE.EUROPEAN_CUP].includes(type);

/** Checks is the game is champions game
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */
export const isChampionsCupGame = (type) => [GAME_TYPE.CHAMPIONS_LEAGUE, GAME_TYPE.COPA_LIBERTADORES].includes(type);

/** Checks is the game is cup
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */

export const isCupGame = (type) => isSimpleCupGame(type) || isChampionsCupGame(type);

/** Checks is the game is season
 * @function
 * @param {number} type - the game type
 * @returns {boolean}
 */

export const isSeasonGame = (type) => isLeagueGame(type) || isCupGame(type);

/** Checking function is round Leg 1
 * @function
 * @param {number} orderNumber - orderNumber of round what need to check
 * @returns {boolean} - result of checking
 */
export const isLeg1 = (orderNumber) => orderNumber % 2 === 1;

/** Checking function is round Leg 2
 * @function
 * @param {number} orderNumber - orderNumber of round what need to check
 * @returns {boolean} - result of checking
 */
export const isLeg2 = (orderNumber) => orderNumber % 2 === 0;

/** Checking function is round par of Champions Cup and it is Leg 2 or final
 * @function
 * @param {number} orderNumber - orderNumber of round what need to check
 * @param {number} gameType - gameType of game
 * @returns {boolean} - result of checking
 */
export const isLeg2OrFinal = (orderNumber, gameType) => isChampionsCupGame(gameType) && (CUP_GAMES_FINAL_ROUND_NUMBER[gameType] === orderNumber || isLeg2(orderNumber));

/** Get Leg Number of round
 * @function
 * @param {number} orderNumber - orderNumber of round what need to check
 * @returns {number} - leg number of round
 */
export const getLegNo = (orderNumber) => (isLeg1(orderNumber) ? 1 : isLeg2(orderNumber) ? 2 : null);

/** Get season game round name
 * @function
 * @param {number} type - the game type
 * @param {number} orderNumber - the round number
 * @returns {string}
 */

export const GetSeasonRoundText = (type, orderNumber) => {
	if (isLeagueGame(type)) {
		return `${i18n.t("common.week")} ${orderNumber}`;
	}
	if (isSimpleCupGame(type)) {
		switch (orderNumber) {
			case 1:
			case 2:
				return `${i18n.t("common.round")} 16 ${i18n.t("common.day")} ${orderNumber}`;
			case 3:
				return `${i18n.t("common.quarterFinals")}`;
			case 4:
				return `${i18n.t("common.semiFinals")}`;
			case 5:
				return `${i18n.t("common.final")}`;
			default:
				break;
		}
	}
	if (isChampionsCupGame(type)) {
		switch (orderNumber) {
			case 1:
			case 2:
				return `${i18n.t("common.round")} 16 ${i18n.t("common.leg")} ${getLegNo(orderNumber)}`;
			case 3:
			case 4:
				return `${i18n.t("common.quarterFinals")} ${i18n.t("common.leg")} ${getLegNo(orderNumber)}`;
			case 5:
			case 6:
				return `${i18n.t("common.semiFinals")} ${i18n.t("common.leg")} ${getLegNo(orderNumber)}`;
			case 7:
				return `${i18n.t("common.final")}`;
			default:
				break;
		}
	}
	return "";
};

/** Checks is the final round event of cup game
 * @function
 * @param {object} event - the event
 * @returns {boolean}
 */

export const isCupGameFinalRound = (event) => {
	if (event?.type !== GAME_EVENT_TYPE.WEEK) {
		return false;
	}
	if (!isCupGame(event?.gameType)) {
		return false;
	}
	return event?.orderNumber === CUP_GAMES_FINAL_ROUND_NUMBER[event?.gameType];
};

export const getOrderNumbersOfCupGameWaitableRounds = (gameType) => {
	if (isSimpleCupGame(gameType)) {
		return [2, 3, 4];
	}

	if (isChampionsCupGame(gameType)) {
		return [2, 4, 6];
	}

	return [];
};

/** Function to set cookie
 * @function
 * @param {string} cname - cookie name
 * @param {string} cvalue - cookie value
 * @param {number} exdays - cookie expiration days
 */
export const setCookie = (cname, cvalue, exdays) => {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	let expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

/** Function to get cookie
 * @function
 * @param {string} cname - cookie name
 * @returns {string}
 */
export const getCookie = (cname) => {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
};

export const required = (message) => {
	return {
		type: VALIDATION_TYPES.REQUIRED,
		whitespace: true,
		message
	};
};

export const getPrintingOptionsValues = (gameType) => {
	if (!isSeasonGame(gameType)) {
		return RESULTS_PRINTING_OPTIONS_VALUES.map((num) => num * 10);
	}

	if (isLeagueGame(gameType)) {
		return RESULTS_PRINTING_OPTIONS_VALUES.map((num) => num * 3);
	}

	if (isSimpleCupGame(gameType)) {
		return RESULTS_PRINTING_OPTIONS_VALUES.map((num) => num + 1);
	}

	return [...RESULTS_PRINTING_OPTIONS_VALUES];
};

export const isReactComponent = (any) => {
	return any && Object.prototype.hasOwnProperty.call(any, "$$typeof") && any["$$typeof"] === Symbol.for("react.element");
};

/** Function using for merge not empty string arguments with spaces
 * @function
 * @param {string} path - location path
 * @returns {object} - query params
 */
export const mergeClassNames = (...classes) => {
	return (
		new Array()
			.concat(classes)
			.filter((str) => typeof str === "string" && str !== "")
			.join(" ") || null
	);
};

/** Function using for checking is value null or undefined
	* @function
	* @param {string} checkArgument - value for checking
	* @returns {boolean} - true if value is null or undefined, otherway false
*/
export const isNullish = (checkArgument) => {
	return (
		checkArgument === null || checkArgument === undefined
	)
};

/** Function to pipe all
 * @param  {...Function} fns
 * @returns piped functions
 */
export const pipe = (...fns) => (arg) => fns.reduce((acc, fn) => fn(acc), arg);

export const mapPenaltyLiveInfoScene = (scene) => {

	/*
		--- sceneInfo data structure ---
		[
			Team1Name,
			Team2Name,
			[one of: PENALTY_SCENE_INFO_NAMES],
			[one of: G (goal) / NG (no goal)],
			[one of: T1 / T2 (scene owner team)],
			R[1-12](roundNo)
		]
	*/
	const sceneInfo = scene.name.split("_");
	const [team1Name, team2Name, enumName, goalInfo, sceneOwnerTeamInfo, penaltyRound] = sceneInfo;
	const teamNo = Number(sceneOwnerTeamInfo.match(/\d+/)?.at(0));
	const roundNo =  Number(penaltyRound.match(/\d+/)?.at(0));
	if (Number.isNaN(teamNo) || Number.isNaN(roundNo)) { return null; }
	const hasGoal = (goalInfo === "G");
	const enumEntry = Object.entries(PENALTY_SCENE_INFO_NAMES).find(([, value]) => value.toUpperCase() === enumName.toUpperCase());
	const enumValue = enumEntry ? Number(enumEntry.at(0)) : null;
	const teamName = `team${teamNo}`;
	return {
		team1Name, team2Name,
		teamName, hasGoal,
		teamNo, roundNo,
		enumName, enumValue
	}
}

export const debounce = (func, timeout = 200) => {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => { func(...args); }, timeout);

		return timer;
	};
}